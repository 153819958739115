import React, { useState, useEffect } from 'react'
import { PageHeader, Seo } from '../../components'
import DataGrid from '../../components/DataGrid'
// Contexts
import { useAuthContext } from '../../context/AuthContext'
import { usePrismic } from '../../context/PrismicContext'
// Utils
import { QServiceReports } from '../../services/q-rervices-reporting'
import { getColumns } from '../../components/DataGrid/reportingConfig/personal-customers'

const PersonalCustomers = () => {
  const { qUser } = useAuthContext()
  const {
    prismicData: { prismicReports },
  } = usePrismic()

  const [loading, setLoading] = useState(false)
  const [data, setData] = useState(null)
  const [error, setError] = useState('')

  const dateEnd = new Date()
  const dateStart = new Date(2019, dateEnd.getMonth(), 1) // Current month only
  const input = {
    legacyAssociateId: qUser?.legacyAssociateId,
    dateStart: dateStart,
    dateEnd: dateEnd,
    associateTypes: ['RETAIL', 'PREFERRED'],
    associateStatuses: [],
    filterTypes: ['SIGNUP_DATE'],
    countryCodes: [],
    treeLevelDepthMax: 1,
  }

  const columns = getColumns(prismicReports)

  const getPersonalCustomersReport = async () => {
    setLoading(true)
    await QServiceReports.Reports.getPersonalCustomersReport({ input })
      .then(response => setData(response))
      .catch(err => setError(err))
    setLoading(false)
  }

  useEffect(() => {
    if (qUser) {
      getPersonalCustomersReport()
    }
  }, [qUser])

  return (
    <>
      <Seo title={prismicReports.personal_customers} />
      <PageHeader exitRoute="/reports">
        {prismicReports.personal_customers}
      </PageHeader>
      <DataGrid columns={columns} data={data} loading={loading} error={error} />
    </>
  )
}

export default PersonalCustomers
